// http://ionicframework.com/docs/theming/
// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
// LOAD FONTS
@import "../theme/variables";

html {
  font-size: 10px;
}

/* iphone 4 and smaller */
@media only screen and (max-height: 480px) {
  html {
    font-size: 9px;
  }
}

/* iphone 5 and similar */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  html {
    font-size: 10px;
  }
}

/* iphone 6, 6s, 7, 8 and similar */
@media only screen and (min-width: 375px) and (max-width: 413px) {
  html {
    font-size: 10px;
  }
}

/* iphone 6+, 6s+, 7+, 8+ and similar*/
@media only screen and (min-width: 414px) and (max-width: 767px) {
  html {
    font-size: 11px;
  }
}

/* iPad Pro 10in and similar tablets and similar */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  html {
    font-size: 12px;
  }
}

/*  large tablets and screens */
@media only screen and (min-width: 1024px) {
  html {
    font-size: 12px;
  }
}


/* any tablets */
//@media only screen and (min-width: 768px) {
//
//}

// Global styles & css4 variables override
ion-header {
  display: block;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

// fix au toscroll when keyboard opens
ion-col {
  position: initial !important;
}

ion-row {
  font-size: 14px;
}

ion-tabs {
  --background: var(--tabs-background);
}

ion-tab-bar {
  &.desktop {
    --ion-tab-bar-color-selected: var(--tab-color);

    ion-tab-button {
      &.tab-selected {
        ion-label {
          border-bottom: 3px solid var(--colors-action);
        }
      }

      ion-label {
        border-bottom: 3px solid transparent;
        font-size: 16px;
        padding-bottom: 2px;
      }
    }
  }

  &:not(.desktop) {
    iion-tab-button ion-label {
      font-size: 10px;
      transition: transform 0.3s ease-in-out;
    }
  }
}

a.white {
  color: var(--colors-white);
}

ion-tab-button {
  --color: var(--tab-color);
  --color-activated: var(--tab-color-activated);
  --color-focused: var(--tab-color-activated);
  --padding-start: 0;
  --padding-end: 0;

  ion-icon {
    transition: transform 0.3s ease-in-out;
  }


  &.md {
    --color: var(--tab-color-md);
  }

  &.tab-selected {
    ion-icon {
      transform: none; //  translate3d(0, -2px, 0);
    }

    ion-label {
      transform: none; // scale3d(1.16667, 1.16667, 1);
    }
  }
}

// missing after migration 3-4
.ion-padding h1:first-child,
.ion-padding h2:first-child,
.ion-padding h3:first-child,
.ion-padding h4:first-child,
.ion-padding h5:first-child,
.ion-padding h6:first-child {
  margin-top: -0.3rem;
}

ion-toast.error {
  --background: var(--clcolors-red-base);
}

.tabs {
  font-family: "Source Sans Pro", sans-serif !important;
}


// fix tabs on ios
.tabs-ios {
  .tabbar {
    padding-bottom: 4px;
    padding-top: 4px;
  }

  .tab-button {
    margin-bottom: 2px;
  }

  .tab-button-icon {
    margin-top: 0;
  }
}

.platform-ios .scroll-content {
  padding: 0 !important;
}

.scroll-content {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}

// force more padding for wizards on web
.ms-platform-web page-nrt .lateral-padding,
.ms-platform-web page-wizard .lateral-padding,
.ms-platform-web page-gears-review .lateral-padding,
.ms-platform-web breathe-exercise-page .lateral-padding,
.ms-platform-web page-craving-tool-ctq .lateral-padding,
.ms-platform-web page-craving-tool-ern .lateral-padding,
.ms-platform-web page-my-coach .lateral-padding,
.ms-platform-web page-profile-completion .lateral-padding,
.ms-platform-web page-interstitial .lateral-padding,
.ms-platform-web cl-modal .lateral-padding,
.ms-platform-web page-inbox .lateral-padding {
  @media only screen and (min-width: 1024px) {
    //padding-left: 200px !important;
    //padding-right: 200px !important;

    padding-left: 134px !important;
    padding-right: 134px !important;
  }
}

@media only screen and (min-width: 768px) {
  .lateral-padding {
    padding-left: 134px !important;
    padding-right: 134px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .lateral-padding {
    //padding-left: 200px !important;
    //padding-right: 200px !important;

    padding-left: 134px !important;
    padding-right: 134px !important;
  }

  ion-modal .lateral-padding {
    padding-left: 134px !important;
    padding-right: 134px !important;
  }
}

.ms-platform-web page-wizard {
  ion-button {
    height: 50px !important;
  }

  cl-audio-player ion-button {
    height: 30px !important;
  }
}

ion-content {
  --padding-end: 0;
  --padding-start: 0;

  --padding-top: constant(safe-area-inset-top) !important; /* iOS 11.0 */
  --padding-top: env(safe-area-inset-top) !important; /* iOS 11.2 */
}

.padding-top-iphone-x {
  padding-top: constant(safe-area-inset-top) !important; /* iOS 11.0 */
  padding-top: env(safe-area-inset-top) !important; /* iOS 11.2 */
}

.padding-bottom-iphone-x {
  padding-bottom: constant(safe-area-inset-bottom) !important; /* iOS 11.0 */
  padding-bottom: env(safe-area-inset-bottom) !important; /* iOS 11.2 */
}

.platform-ios cl-input .text-input {
  margin-bottom: 8px !important;
}

// remove header border on Android
.header-md::after,
.tabs-md[tabsPlacement="top"] > .tabbar::after,
.footer-md::before,
.tabs-md[tabsPlacement="bottom"] > .tabbar::before {
  content: none;
}

// CUSTOM STYLING FOR ALERTS
.cl-range-select {
  .alert-head {
    color: var(--colors-primary);
  }
}

// CUSTOM TAGS
[cl-no-scroll] {
  height: 100% !important;
  overflow: hidden;
}

[cl-no-padding] {
  padding: 0;
}

[cl-no-padding-side] {
  padding-left: 0;
  padding-right: 0;
}

[cl-uppercase] {
  text-transform: uppercase;
}

[cl-text-left] {
  text-align: left;
}

[cl-box-shadow] {
  box-shadow: 5px 5px 23px 3px rgba(19, 94, 132, 0.45);
}

.link-to-post {
  cursor: pointer;
}

ion-item[no-lines] {
  --border-color: transparent;
}

ion-footer {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
}

// CUSTOM ICONS
.icon {
  &[class*="cl-"] {
    // Instead of using the font-based icons
    // We're applying SVG masks
    height: 1em;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    mask-size: contain;
    width: 1em;
  }

  // define icons and their images
  &[class*="cl-icon-my-journey"] {
    mask-image: url("/assets/imgs/icon-my-journey.png");
  }

  &[class*="cl-icon-exercises"] {
    mask-image: url("/assets/imgs/icon-exercises.png");
  }

  &[class*="cl-icon-community"] {
    mask-image: url("/assets/imgs/icon-community.png");
  }

  &[class*="cl-icon-account"] {
    mask-image: url("/assets/imgs/icon-account.png");
  }

  &[class*="cl-icon-stress-test"] {
    mask-image: url("/assets/imgs/icon-stress-test.png");
  }

  &[class*="cl-icon-checkin"] {
    mask-image: url("/assets/imgs/icon-checkin.png");
  }

  &[class*="cl-icon-stress-meter"] {
    mask-image: url("/assets/imgs/icon-stress-meter.png");
  }

  &[class*="cl-icon-close-now"] {
    mask-image: url("/assets/imgs/icon-close-now.png");
  }

  &[class*="cl-icon-head-exercise"] {
    mask-image: url("/assets/imgs/icon-head-exercise.png");
  }

  &[class*="cl-icon-meditate"] {
    mask-image: url("/assets/imgs/icon-meditate.png");
  }

  &[class*="cl-icon-tracker"] {
    mask-image: url("/assets/imgs/icon-tracker.png");
  }

  &[class*="cl-icon-cake"] {
    mask-image: url("/assets/imgs/icon-cake.png");
  }
}

// FIX Home icon in tabs on iOS
.ion-ios-home-outline::before {
  content: "\f448";
}

/* iPad and iPad Pro */
::part(content) {
  --background: transparent;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

// debugging
.pre-wrapped {
  white-space: pre-wrap;
}

// Ion-item / ion-label / ion-input - start
.item-label-stacked .input-wrapper,
.item-label-floating .input-wrapper {
  //-webkit-box-direction: normal;
  //-webkit-box-orient: vertical;
  flex: 1;
  flex-direction: column;
}


ion-input.custom-goal-disabled .native-input[disabled].sc-ion-input-md {
  opacity: 1;
}

.item-label-stacked ion-select,
.item-label-floating ion-select {
  align-self: stretch;
  max-width: 100%;
}

.label-ios {
  margin: 11px 8px 11px 0;
}

.label-md {
  margin: 13px 8px 13px 0;
}

ion-item {
  --background-focused: transparent;
  --highlight-color-focused: none;
}

cl-common-radio ion-item {
  --border-color: transparent;
}

ion-item:not(.item-has-value) ion-label.label-floating.sc-ion-label-md-h,
ion-item:not(.item-has-focus) ion-label.label-floating.sc-ion-label-md-h,
ion-item:not(.item-has-value) ion-label.label-floating.sc-ion-label-ios-h,
ion-item:not(.item-has-focus) ion-label.label-floating.sc-ion-label-ios-h {
  transform: translate3d(0, 27px, 0) !important;
  transform-origin: left top;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;

  @media only screen and (min-width: 768px) {
    transform: translate3d(0, 27px, 0) !important;
  }
}

ion-item.item-has-value ion-label.label-floating.sc-ion-label-md-h,
ion-item.item-has-focus ion-label.label-floating.sc-ion-label-md-h,
ion-item.item-has-value ion-label.label-floating.sc-ion-label-ios-h,
ion-item.item-has-focus ion-label.label-floating.sc-ion-label-ios-h {
  color: #fff;
  transform: translate3d(0, 0, 0) scale(0.8) !important;
}

page-login {
  ion-item {
    &.item-has-focus,
    &.item-has-value {
      ion-label {
        visibility: hidden;
      }
    }
  }
}

// Ion-item / ion-label / ion-input - end

.notvisible {
  visibility: hidden !important;
}


.item {
  contain: content;
}

.item-block {
  align-items: center;
  border: 0;
  //-webkit-box-align: center;
  //-webkit-box-pack: justify;
  color: inherit;
  display: flex;
  //-ms-flex-align: center;
  //-ms-flex-pack: justify;
  font-weight: normal;
  justify-content: space-between;
  line-height: normal;
  margin: 0;
  min-height: 4.4rem;
  overflow: hidden;
  padding: 0;
  text-align: initial;
  text-decoration: none;
  width: 100%;
}

.item-inner {
  align-items: inherit;
  align-self: stretch;
  border: 0;
  //-webkit-box-align: inherit;
  //-webkit-box-direction: inherit;
  //-webkit-box-flex: 1;
  //-webkit-box-orient: inherit;
  display: flex;
  flex: 1;
  //-ms-flex-align: inherit;
  flex-direction: inherit;
  //-ms-flex-item-align: stretch;
  margin: 0;
  min-height: inherit;
  overflow: hidden;
  padding: 0;
}

.input-wrapper {
  align-items: inherit;
  align-self: stretch;
  //-webkit-box-align: inherit;
  //-webkit-box-direction: inherit;
  //-webkit-box-flex: 1;
  //-webkit-box-orient: inherit;
  display: flex;
  flex: 1;
  //-ms-flex-align: inherit;
  flex-direction: inherit;
  //-ms-flex-item-align: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios,
.searchbar-left-aligned.sc-ion-searchbar-md-h .searchbar-input.sc-ion-searchbar-md {
  border-bottom: 1px solid var(--clcolors-grey-dark);
  border-radius: 5px !important;
  font-size: 1.4rem;
}

cl-account-profile {
  cl-range-select {
    width: 100%;

    ion-item {
      --inner-padding-start: 3vw;
      --inner-padding-end: 4vw;
    }
  }

  cl-input {
    .input-has-focus ion-label,
    .input-has-value ion-label,
    ion-label {
      --color: var(--clcolors-blue-base);
    }
  }

  ion-input {
    --padding-start: 10px;
    --background: transparent;
    --color: var(--colors-primary);
    --placeholder-color: var(--colors-primary);
    --placeholder-opacity: 1;
    font-size: 1.6rem;

    input {
      &.native-input.sc-ion-input-ios,
      &.native-input.sc-ion-input-md {
        background: #f7f7f7;
        color: var(--colors-primary);
        height: 3rem;
      }
    }
  }
}

// Tracker popover
.tracker-popover {
  .popover-content {
    left: calc(50vw - 125px) !important;
    width: 250px;

    @media only screen and (max-height: 568px) {
      left: calc(50vw - 125px) !important;
      width: 250px;
    }

    @media only screen and (min-width: 768px) {
      left: calc(50vw - 175px) !important;
      width: 350px;
    }
  }
}

// CUSTOM WEB
//.ms-platform-web {
//  .bottom-bg-background {
//    display: none;
//  }
//}

page-my-coach-conversation-ctq {
  textarea {
    min-height: 40px !important;
  }
}

// Only web, but common to the 3 clarity_programs
page-dashboard,
page-home {
  &.desktop {
    .heading-dashboard {
      align-items: center;
      background: rgba(34, 109, 151, 0.3);
      border-radius: 1px;
      color: #fff;
      display: inline-flex;
      font-family: "Source Sans Pro Semibold", sans-serif;
      font-size: 30px;
      justify-content: center;
      margin: 20px;
      min-height: 54px;
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

// Only desktop styles (ion-app.ion-page.desktop)
.heading-desktop {
  align-items: center;
  background: rgba(34, 109, 151, 0.3);
  border-radius: 1px;
  color: #fff;
  display: inline-flex;
  font-family: "Source Sans Pro Semibold", sans-serif;
  font-size: 2.1rem;
  justify-content: center;
  margin: 32px 16px;
  min-height: 54px;
  padding-left: 20px;
  padding-right: 20px;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 767px) {
    margin: 16px;
  }
}

.ion-page.desktop {
  .item-label-stacked .input-wrapper,
  .item-label-floating .input-wrapper {
    flex-direction: column;
    // max-height: 48px;
  }

  .web-modal::part(content) {
    // Default (min-width: 980px & max-width: 1999px)
    margin: 0 auto;
    max-width: 940px;
    width: 100%;
  }

  .web-modal::part(content) {
    height: 100%;
  }

  @media (min-width: 1200px) {
    // Large display
    .container,
    .web-modal::part(content) {
      margin: 0 auto;
      max-width: 996px;
    }

    .web-modal::part(content) {
      max-height: 100vh;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    // Portrait tablets
    .container,
    .web-modal::part(content) {
      margin: 0 auto;
    }

    .web-modal::part(content) {
      max-width: 768px;
    }
  }

  @media (max-width: 767px) {
    // Phones to tablets
    .container {
      margin: 0 auto;
      width: auto;
    }

    .web-modal::part(content) {
      max-height: none;
      max-width: none;
      width: 100%;
    }
  }
}

::part(content).sc-ion-modal-md,
::part(content).sc-ion-modal-ios {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;

  @media (min-width: 1200px) {
    --width: 100%;
    --height: 100%;
  }
}

// this style has side effects on the OPEN_MODAL action and in modals-transition.animations
// if you change it, assure the check those places too
.web-modal::part(content) {
  --width: 100%;
  --height: 100%;
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) !important;
}

// adds faded overlay to be used in animations
.dimmed {
  position: absolute;
}

.dimmed::after {
  background: rgba(0, 0, 0, 0.1);
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

// Hotfix for ion-select which doesn't show the scrollbar
.alert-radio-group::-webkit-scrollbar {
  display: block !important;
  width: 6px;
}

.alert-radio-group::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: rgb(169, 169, 169);
  outline: 1px solid rgb(112, 128, 144);
}

ion-alert {
  z-index: 50000 !important;
}

.code-prompt {
  .alert-input {
    text-transform: uppercase !important;
  }
}

strong.dpp-onboarding.bolder {
  font-family: "Source Sans Pro Bold";
}

span.dpp-onboarding.normal-weight {
  font-weight: 400;
}

ion-button.dark-blue-theme {
  --blue: #007db2;

  --border-radius: 10px;
  --padding-start: 1.3em;
  --padding-end: 1.3em;

  font-family: "Source Sans Pro";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  height: $ion-button-height;
  letter-spacing: 0.15em;
  line-height: 1.1em;

  text-align: center;
  text-transform: uppercase;

  &.light-button {
    --background: #fff;
    --background-activated: #fff;
    --background-focused: #fff;
    --background-hover: #fff;
    --color: var(--blue);
    --color-activated: var(--blue);
    --color-focused: var(--blue);
    --color-hover: var(--blue);
  }

  &.dark-button {
    --background: var(--blue);
    --background-activated: var(--blue);
    --background-focused: var(--blue);
    --background-hover: var(--blue);
    --color: #fff;
    --color-activated: #fff;
    --color-focused: #fff;
    --color-hover: #fff;
  }

  @media only screen and (min-width: 500px) {
    font-size: 1.8rem;
    line-height: 1.3em;
  }
}

.integration-status-modal-component::part(content) {
  max-width: none !important;
  width: 100% !important;
}

.custom-bullet {
  background: #fff !important;
}

.custom-bullet-active {
  background: var(--clcolors-orange-base) !important;
}

.video > .swiper-pagination {
  visibility: hidden;
}

.swiper-slide {
  display: flex;
  font-size: 18px;
  justify-content: center;
  text-align: center;
}

.safari {
  .vjs-control-bar {
    transform: translateZ(1px);
  }
}

ion-modal.date-time-picker {
  --background: transparent;
  --box-shadow: none;

  ion-content {
    --background: transparent;

    &::part(scroll) {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}

.bottom-sheet-modal {
  --backdrop-opacity: 1;
  backdrop-filter: blur(3px);

  &::part(backdrop) {
    background: #3b3b3b;
  }

  &::part(handle) {
    background: var(--ion-color-primary);
    height: 4px;
    margin-top: 5px;
    width: 100px;
  }

  &::part(content) {
    border-radius: 20px 20px 0 0;
    box-shadow: 0 0 20px 5px rgb(0 0 0 / 32%);
  }
}

ion-checkbox.light {
  --background: var(--colors-white);
  --background-checked: var(--colors-white);
  --background-activated: var(--colors-white);
  --border-color: var(--colors-white);
  --border-color-checked: var(--colors-white);
  --checkmark-color: var(--colors-action);
  --checkmark-width: 2px;
  background-color: var(--colors-white);
  border: 1px solid var(--blue);
  border-radius: 5px;
  height: 24px;
  width: 24px;
}
