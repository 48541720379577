// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// Font path is used to include ionicons,
// roboto, and noto sans fonts
//$font-path: "../assets/fonts";

// The app direction is used to include
// rtl styles in your app. For more info, please see:
// http://ionicframework.com/docs/theming/rtl-support/
//$app-direction: ltr;

// @import "ionic.globals";

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass variables found in Ionic's source scss files.
// To view all the possible Ionic variables, see:
// http://ionicframework.com/docs/theming/overriding-ionic-variables/

// Named Color Variables
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic uses a Sass map of
// colors so you can add, rename and remove colors as needed.
// The "primary" color is the only req
// uired color in the map.

:root {
  --colors-primary: #3c9de0;
  --colors-secondary: #32db64;
  --colors-action: #fda533;
  --colors-danger: #f2473d;
  --colors-white: #fff;
  --colors-blue: #379bd2;

  --ion-color-primary: var(--colors-primary);
  --ion-color-secondary: var(--colors-secondary);
  --ion-color-warning: var(--colors-action);
  --ion-color-danger: var(--colors-danger);
  --ion-color-blue: var(--colors-blue);

  //--ion-default-font: "Source Sans Pro";
  //--ion-font-family: "Source Sans Pro";
}

:root {
  --other-colors-primary: #55b3e7;
  --other-colors-secondary: #32db64;
  --other-colors-action: #fda533;
  --other-colors-white: #fff;
}

:root {
  --ion-color-action: #fda533;
  --ion-color-action-rgb: 253, 165, 51;
  --ion-color-action-contrast: #fff;
  --ion-color-action-contrast-rgb: 255, 255, 255;
  --ion-color-action-shade: #df912d;
  --ion-color-action-tint: #fdae47;
  --ion-color-action-shade-activated: var(--colors-primary);
}

.ion-color-action {
  --ion-color-base: var(--ion-color-action);
  --ion-color-base-rgb: var(--ion-color-action-rgb);
  --ion-color-contrast: var(--ion-color-action-contrast);
  --ion-color-contrast-rgb: var(--ion-color-action-contrast-rgb);
  --ion-color-shade: var(--ion-color-action-shade);
  --ion-color-tint: var(--ion-color-action-tint);

  &.ion-activated {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-white);
    --ion-color-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-shade: var(--ion-color-action-shade-activated);
    --ion-color-tint: var(--ion-color-primary-tint);
    --color: #fff;
  }
}

:root {
  --ion-color-white: #fff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: var(--colors-primary);
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #fff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);

  &.ion-activated {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-white);
    --ion-color-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-shade: var(--ion-color-primary);
    --ion-color-tint: var(--ion-color-primary-tint);
    --color: #fff;
  }
}

.ion-color-blank {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);

  &.ion-activated {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-base-rgb: var(--ion-color-primary-rgb);
    --ion-color-contrast: var(--ion-color-white);
    --ion-color-contrast-rgb: var(--ion-color-white-rgb);
    --ion-color-shade: var(--ion-color-primary-shade);
    --ion-color-tint: var(--ion-color-primary-tint);
  }
}

:root {
  --ion-color-toggle: #fda533;
  --ion-color-toggle-rgb: 253, 165, 51;
  --ion-color-toggle-contrast: #000;
  --ion-color-toggle-contrast-rgb: 0, 0, 0;
  --ion-color-toggle-shade: #df912d;
  --ion-color-toggle-tint: #fdae47;
}

.ion-color-toggle {
  --ion-color-base: var(--ion-color-toggle);
  --ion-color-base-rgb: var(--ion-color-toggle-rgb);
  --ion-color-contrast: var(--ion-color-toggle-contrast);
  --ion-color-contrast-rgb: var(--ion-color-toggle-contrast-rgb);
  --ion-color-shade: var(--ion-color-toggle-shade);
  --ion-color-tint: var(--ion-color-toggle-tint);
}

:root {
  --ion-color-checkbox: #f7f7f7;
  --ion-color-checkbox-rgb: 247, 247, 247;
  --ion-color-checkbox-contrast: #fda533;
  --ion-color-checkbox-contrast-rgb: 253, 165, 51;
  --ion-color-checkbox-shade: #df912d;
  --ion-color-checkbox-tint: #fdae47;
}

.ion-color-checkbox {
  --ion-color-base: var(--ion-color-checkbox);
  --ion-color-base-rgb: var(--ion-color-checkbox-rgb);
  --ion-color-contrast: var(--ion-color-checkbox-contrast);
  --ion-color-contrast-rgb: var(--ion-color-checkbox-contrast-rgb);
  --ion-color-shade: var(--ion-color-checkbox-shade);
  --ion-color-tint: var(--ion-color-checkbox-tint);
}

:root {
  --ion-color-success: #3cd59d;
  --ion-color-success-rgb: 253, 165, 51;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3bd49c;
  --ion-color-success-tint: #40dfa4;
  --ion-color-success-shade-activated: var(--colors-primary);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);

  &.ion-activated {
    --ion-color-base: var(--ion-color-blue) !important;
    --ion-color-base-rgb: var(--ion-color-primary-rgb) !important;
    --ion-color-contrast: var(--ion-color-white) !important;
    --ion-color-contrast-rgb: var(--ion-color-white-rgb) !important;
    --ion-color-shade: var(--ion-color-action-shade-activated) !important;
    --ion-color-tint: var(--ion-color-primary-tint) !important;
    --color: #fff;
  }
}

//$clcolors: (
//  "orange": (
//    base: #fda533,
//    light: #fdb75c,
//    dark: #da8e17,
//  ),
//  "blue": (
//    base: #2794d0,
//    light: #3ab2f4,
//    dark: #2733ff,
//    sky: #45ace6,
//    background: #399dd6,
//    slideBlue: #65bcec,
//    footer: #3b9ddf
//  ),
//  "listblue": (
//    base: #8dd2f9,
//    light: #d1edfd
//  ),
//  "grey": (
//    base: #f7f7f7,
//    dark: #ccc,
//    light: #aab1c2,
//    superlight: #f7f7f7
//  ),
//  "red": (
//    base: #f00
//  ),
//  "white": (
//    base: #fff
//  ),
//  "pink": (
//    base: #b12cad,
//    light: #d3b4d5
//  )
//);

:root {
  --clcolors-orange-base: #fda533;
  --clcolors-orange-light: #fdb75c;
  --clcolors-orange-dark: #da8e17;

  --clcolors-blue-base: #2794d0;
  --clcolors-blue-light: #3ab2f4;
  --clcolors-blue-dark: #2733ff;
  --clcolors-blue-sky: #45ace6;
  --clcolors-blue-background: #399dd6;
  --clcolors-blue-slideBlue: #65bcec;
  --clcolors-blue-footer: #3b9ddd;

  --clcolors-listblue-base: #8dd2f9;
  --clcolors-listblue-light: #d1edfd;

  --clcolors-grey-base: #f7f7f7;
  --clcolors-grey-dark: #ccc;
  --clcolors-grey-light: #aab1c2;
  --clcolors-grey-superlight: #f7f7f7d9;

  --clcolors-red-base: #f00;

  --clcolors-white-base: #fff;

  --clcolors-pink-base: #b12cad;
  --clcolors-pink-light: #d3b4d5;

  --bottom-bg-gradient-height: 80px;

  --breath-color: #f9ebd5;
  --ion-color-base: #fff;
  --my-journey-card-border-color: rgba(255, 255, 255, 0.8);

  --rain-button-size: 90px;
  --navbar-ios-height: 40px;
  --navbar-ios-height-times-3: calc(var(--navbar-ios-height) * 3);

  --pages-content-web-background: transparent linear-gradient(to bottom, #2894d0 0%, #abd9fa 10%, #c1e0f7 15%, #fff 25%, #fff 75%, rgba(40, 148, 208, 0.4) 85%, rgba(40, 148, 208, 0.4) 100%);
}

$breath-color: #f9ebd5;


$bottom-bg-gradient-height: 80px;
$my-journey-card-border-color: rgba(255, 255, 255, 0.8);
$breathe-button-size: 90px;
$rain-button-size: 90px;

// NO LONGER NEEDED - We're using CSS variables now and all are available in <PROGRAM>.app.scss files
// Custom per program variables
//@import "include.variables";

//@function cl-color($name, $variation: "base") {
//  $variations: map-get($clcolors, $name);
//  $value: map-get($variations, $variation);
//  @return $value;
//}

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here
$tabs-ios-tab-color-inactive: var(--colors-primary);
$tabs-ios-tab-icon-color-active: var(--colors-action);
$tabs-ios-tab-text-color-active: var(--colors-action);

// APP Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

$tabs-md-tab-color-inactive: var(--colors-primary);
$tabs-md-tab-icon-color-active: var(--colors-action);
$tabs-md-tab-text-color-active: var(--colors-action);

:root {
  // inactive doesn't exist anymore -- seems we can style the default and the *active* now
  // TODO: To be tested -- more options here https://ionicframework.com/docs/theming/advanced
  --ion-tab-bar-color: var(--colors-primary);
  --ion-tab-bar-color-selected: var(--colors-action);
}

$toggle-ios-width: 58px;
$toggle-ios-height: 32px;
$toggle-ios-border-width: 4px;
$toggle-ios-background-color-on: var(--clcolors-orange-base);
$toggle-ios-background-color-off: var(--clcolors-grey-light);
$toggle-ios-border-color-off: var(--clcolors-grey-light);
$toggle-ios-handle-background-color: #fff;
$toggle-ios-handle-box-shadow: none;

$datetime-ios-placeholder-color: var(--clcolors-blue-base);
$ion-button-height: 2.2em;

@import "mixins";
