// Custom styles for ERN -- will override default

:root {
  --colors-primary: #3ca8e4;
  --colors-secondary: #60aed5;
  --colors-action: #fda533;
  --colors-white: #fff;
  --colors-third: #2a87bb;
  --colors-green: #53ca98;

  --extend-program-bg-color: #3ab2f4;
  --ion-color-base: #fff;

  --tabs-background: #f8f8f8;
  --tab-color: var(--colors-primary);
  --tab-color-md: rgba(60, 168, 228, 0.7);
  --tab-color-activated: var(--colors-action);

  --input-disabled-color: rgba(60, 168, 228, 0.4);

  // welcome tour
  --welcome-tour-background-image: url("./assets/imgs/ern-field-bg-blurred.jpg");
  --welcome-tour-bottom-image: url("./assets/imgs/ern-waves-bg-welcometour.png");

  --slide-content-gradient-start-color: rgba(60, 168, 228, 0.5);
  --slide-content-gradient-middle-color: rgba(60, 168, 228, 0.8);
  --slide-content-gradient-end-color: rgba(60, 168, 228, 1);

  // dashboard
  --todays-section-background-desktop: rgba(40, 148, 208, 0.54);
  --todays-section-background-notdesktop: rgba(40, 148, 208, 0.8);

  --todays-section-holder-background-desktop: var(--clcolors-blue-sky);
  --todays-section-holder-background-notdesktop: var(--colors-primary) url("../../../libs/clarity-core/src/assets/imgs/bg/ern-main-bg.jpg") center center no-repeat;

  --todays-lesson-card-background-image-desktop: url("./assets/imgs/ern_todays_module_desktop.png");

  --community-section-header-background-notdesktop: url("./assets/imgs/ern-waves-green.jpg") no-repeat;
  --community-wrapper-background-notdesktop: linear-gradient(180deg, #b0e8a9 0%, #b0e8a9 30%, #a1d4f1 60%, var(--colors-primary) 100%);
  --community-wrapper-padding-bottom-notdesktop: 90px;

  --now-container-iphonese-bottom: 85px;
  --now-container-iphone8-bottom: 120px;
  --now-container-iphone8plus-bottom: 110px;
  --now-container-iphonex-bottom: 120px;
  --now-container-iphonexr-bottom: 140px;
  --now-container-ipad-bottom: 120px;
  --now-container-large-bottom: 140px;

  // lessons and play page
  --lessons-page-background-color: var(--colors-third);
  --lessons-page-wrapper-background: var(--lessons-page-background-color) url("../../../libs/clarity-core/src/assets/imgs/bg/ern-field-bg.jpg") top center no-repeat;
  --lessons-page-bottom-background: linear-gradient(to bottom, #a9cce1 0%, var(--lessons-page-background-color) 100%);

  --lesson-card-shadow-wrapper-background-color: #45bcff;
  --lesson-card-current-background-color: var(--colors-green);

  --play-page-title-holder-min-height: 320px;
  --play-page-player-holder-margin-top: -210px;
  --play-page-steps-holder-margin-top: -250px;
}

page-welcome-tour {
  ion-content {
    --background: none;
    background-attachment: fixed;
    background-color: var(--colors-primary);
    background-image: url("./assets/imgs/ern-field-bg-blurred.jpg");
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  .bottom-bg {
    background-image: url("./assets/imgs/ern-waves-bg-welcometour.png");
  }
}

page-craving-tool {
  --play-page-content-bg-color: var(--colors-primary); // #2887ba;
  --play-page-wrapper-bg-color: var(--colors-primary); // #2887ba;

  .title-holder {
    background: var(--colors-primary) url("../../../libs/clarity-core/src/assets/imgs/bg/ern-field-bg.jpg") top center no-repeat;
    color: rgba(250, 250, 250, 1) !important;
    margin-top: 0 !important;
    min-height: 320px;
    padding-top: 60px;
  }

  .waves-bg {
    background: var(--colors-primary) url("../../../libs/clarity-core/src/assets/imgs/bg/waves-bg-transparent.png") no-repeat;
  }

  .bottom-bg-background {
    background: linear-gradient(to bottom, #a9cce1 0%, #2887ba 100%);
  }
}

page-exercises {
  --exercises-content-bg-color: var(--colors-primary);
  --exercises-content-color-web: transparent;

  .other-exercises {
    background: linear-gradient(180deg, #acd9f3 0%, var(--colors-primary) 30%, var(--colors-primary) 60%);
  }

  .bottom-bg-background {
    background: linear-gradient(to bottom, #acd9f3 0%, var(--colors-primary) 100%);
  }
}

page-my-journey {
  &.desktop {
    --my-journey-content-bg-color: var(--colors-primary);
    --my-journey-content-color-web: transparent;
  }

  &:not(.desktop) {
    ion-content {
      --background: var(--colors-primary);
    }

    .weeks-section-holder {
      background: #2887ba url("../../../libs/clarity-core/src/assets/imgs/bg/ern-field-bg.jpg") 0 0 no-repeat;
    }

    .card-wrapper.locked,
    .card-wrapper.current,
    .card-wrapper.completed {
      background-color: #45bcff !important;
      border-top: 2px solid var(--my-journey-card-border-color) !important;
    }

    .themes-section-holder {
      background: #2887ba url("./assets/imgs/ern-myjourney-waves-dark.png") top center no-repeat;
    }

    .bottom-section-holder {
      background: #2887ba linear-gradient(to bottom, #2887ba 0%, #2f93c9 5%, #2f93c9 45%, #9ed3f0 100%, #9ed3f0 100%);
    }

    .footer-section-bottom-holder {
      background: linear-gradient(to bottom, #60b6e9 0%, #2887ba 100%);
    }
  }
}

page-custom-path {
  .card-wrapper.locked,
  .card-wrapper.current,
  .card-wrapper.completed,
  .modules-list {
    background-color: #45bcff !important;
    border: 2px solid var(--my-journey-card-border-color);
  }
}


page-community {
  // fix pull down/up color on iOS
  ion-content {
    --background: linear-gradient(to bottom, var(--colors-primary) 0%, var(--colors-secondary) 50%, var(--colors-secondary) 100%);
    --page-community--gradient-color: var(--colors-primary);
  }

  .community-waves-bg {
    background: #fff url("./assets/imgs/ERN-community-wave.png") top center no-repeat;
  }

  .community-posts-holder {
    background: var(--colors-primary);
  }

  .journal-block {
    background: #fff url("./assets/imgs/ern-field-bg-blurred-to-white.jpg");
  }

  .video-block {
    background: var(--colors-primary);
  }

  &.desktop {
    .community-posts-holder {
      --page-community--holder-bg: var(--colors-primary);
    }
  }
}

page-account.desktop {
  --account-bg-color: var(--colors-primary);
}

page-new-theme-week,
page-new-custom-week,
page-custom-path,
page-select-theme,
page-restart-program,
page-next-steps {
  ion-content {
    --background: var(--extend-program-bg-color);
  }

  .page-background {
    background: var(--extend-program-bg-color);
  }
}

page-gears-review,
breathe-exercise-page {
  .results-title {
    background: linear-gradient(to bottom, #3ca8e4 0%, #3dace8 68%, #90d8ff 80%, #fff 100%);
  }

  .task-text {
    background: linear-gradient(to bottom, #3ca8e4 0%, #3dace8 68%, #90d8ff 80%, #fff 100%);
  }
}
