:host {
  --now-wrapper-substract-width: 168px;
}

@mixin now-wrapper-width {
  max-width: 800px;
  width: calc(910px - var(--now-wrapper-substract-width));

  @media (min-width: 1200px) {
    max-width: calc(1141px - var(--now-wrapper-substract-width));
  }

  @media (max-width: 767px) {
    max-width: calc(100% - 20%);
  }
}

@mixin typography-caption-1() {
  /*! typography-caption-1 */
  font-family: "Source Sans Pro Bold", sans-serif;
  font-size: 3.2rem;
  font-style: normal;
  line-height: 40px;
}

@mixin typography-caption-2() {
  /*! typography-caption-2 */
  font-family: "Source Sans Pro Bold", sans-serif;
  font-size: 2.8rem;
  font-style: normal;
  line-height: 36px;
}

@mixin typography-caption-3() {
  /*! typography-caption-3 */
  font-family: "Source Sans Pro Bold", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 24px;
}

@mixin typography-caption-4() {
  /*! typography-caption-4 */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  line-height: 18px;
}

@mixin typography-footnote() {
  /*! typography-footnote */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

@mixin typography-body() {
  /*! typography-body */
  font-family: "Source Sans Pro";
  font-size: 2rem;
  font-style: normal;
  line-height: 30px;
}

@mixin for($devices...) {
  // https://css-tricks.com/snippets/css/media-queries-for-standard-devices/
  // https://stackoverflow.com/a/58087447/390564

  @each $device in $devices {
    @if $device == iphone-5 {
      @media only screen
        and (min-width: 320px)
        and (max-height: 568px)
        and (-webkit-min-device-pixel-ratio: 2)
        and (orientation: portrait) { @content; }
    } @else if $device == iphone-6 {
      @media only screen
        and (min-width: 375px)
        and (max-height: 667px)
        and (orientation: portrait) { @content; }
    } @else if $device == iphone-6-plus {
      @media only screen
        and (min-width: 414px)
        and (max-height: 736px)
        and (orientation: portrait) {  @content; }
    } @else if $device == iphone-x {
      @media only screen
        and (min-width: 375px)
        and (min-height: 737px)
        and (max-height: 812px)
        and (-webkit-min-device-pixel-ratio: 3)
        and (orientation: portrait) {  @content; }
    } @else if $device == iphone-11 {
      @media only screen
        and (min-width: 375px)
        and (min-height: 813px)
        and (max-height: 896px)
        and (-webkit-min-device-pixel-ratio: 2)
        and (orientation: portrait) {  @content; }
    } @else if $device == iphone-12 {
      @media only screen
        and (min-width: 390px)
        and (min-height: 813px)
        and (max-height: 844px)
        and (-webkit-min-device-pixel-ratio: 3)
        and (orientation: portrait) {  @content; }
    } @else if $device == iphone-12-mini {
      @media only screen
        and (min-width: 360px)
        and (min-height: 780px)
        and (max-height: 812px)
        and (-webkit-min-device-pixel-ratio: 3)
        and (orientation: portrait) {  @content; }
    } @else if $device == ipad {
      @media only screen
        and (min-width: 768px)
        and (max-width: 1024px) { @content; }
    } @else if $device == ipad-pro {
      @media (min-width: 1024px) { @content; }
    }
  }
}
